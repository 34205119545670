import { AxiosInstance, AxiosRequestConfig } from "axios";
import { HTTP_CONSTANTS } from "../../constants/http-constants";
import { environment } from "../../environment/environment";

export default function HttpInterceptor(axios: AxiosInstance) {
	axios.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			config.url = config.url
				?.replace(HTTP_CONSTANTS.protocol, environment.protocol)
				.replace(HTTP_CONSTANTS.baseAddress, environment.baseAddress);
			
			if (config.headers === undefined || config.headers === null) config.headers = {};
			config.headers['product_selection'] = 'flamme';

			return config;
		},
		(error: any) => {
			return Promise.reject(error);
		}
	);
}
