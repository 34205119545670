import { useCallback, useEffect, useState } from "react";
import ProductEntity, { ProductBuilder, ProductMedia, ProductOption, ProductOptions } from "../../models/data/product";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ProductService from "../../services/ui/product-service";
import StatefullInput from "../statefull-input/statefull-input";
import { FaChevronLeft, FaFloppyDisk, FaPlus, FaRotateLeft, FaTrash } from "react-icons/fa6";
import StatefullFileSelect, { FileChangeEvent } from "../statefull-file-select/statefull-file-select";
import EventEmitter from "../../services/event/event-emitter";
import { HandleHelper } from "../../helpers/handle-helper";
import { diff } from 'json-diff-ts';
import AdminVariantEditor from "./admin-variant-editor";

export function AdminProductImage({ product, mediaItem, deleteHandler, orderChangeHandler }: { product: ProductEntity, mediaItem: ProductMedia, deleteHandler: (media: string) => void, orderChangeHandler: (media: ProductMedia) => void }) {
    const [media, setMedia] = useState<ProductMedia>(mediaItem);

    useEffect(() => {
        if (media.order === mediaItem.order) return;
        orderChangeHandler(media);
    }, [media, orderChangeHandler, mediaItem])

    return <div className="d-flex flex-column justify-content-between align-items-stretch h-100">

        <StatefullInput stateId={"available"} classes={'w-100'} label={''} defaultValue={media.order === undefined ? '0' : media.order.toString()} inputType={"select"} autocompleteType={""} options={[...Array(product.media.length).keys()].map((n, i) => { return { view: (i + 1).toString(), value: i.toString() } })} onChangeCallback={(_: string) => {
            if (media.order === Number.parseInt(_)) return;
            setMedia({ ...media, order: Number.parseInt(_) })
        }} />
        <div className="mt-1 border border-dark rounded-3 overflow-hidden d-flex align-items-center justify-content-center flex-column bg-light position-relative flex-fill">
            <img src={media.url} alt={product.handle} className="d-block" style={{ maxWidth: 120 }} />
            <div className="position-absolute top-0 end-0 p-1">
                <button className="btn btn-outline-danger" onClick={() => deleteHandler(media.url)}><FaTrash size={15} /></button>
            </div>
        </div>
    </div>
}

export default function AdminProductPage({ pageType }: { pageType: string }) {

    const [searchParams] = useSearchParams();
    const { handle } = useParams();
    const navigation = useNavigate();
    const [isNew] = useState<boolean>(searchParams.get('isNew') === 'true')
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    // product
    const [product, setProduct] = useState<ProductEntity>();
    const [originalProduct, setOriginalProduct] = useState<ProductEntity>();

    // media
    const [productImageChange] = useState<EventEmitter<FileChangeEvent>>(new EventEmitter<FileChangeEvent>());
    const [newProductImage, setNewProductImage] = useState<FileChangeEvent>();

    // metafields
    const [metafields, setMetafields] = useState<{ description: string }>();

    // options
    const [newOption, setNewOption] = useState<ProductOptions>();

    // option values
    const [newOptionValue, setNewOptionValue] = useState<ProductOption>();

    const fetchProduct = (handle: string) => {
        ProductService.GetProduct(handle, {
            success: (_: ProductEntity) => {
                if (_.externalRef === null) _.externalRef = '';
                setOriginalProduct({ ..._ });
                setProduct({ ..._ });
                setMetafields({ ..._.metafields })
            },
            error: (err: any) => {
                alert('failed to fetch product');
                console.error("failed to load product", err);
            }
        }, true)
    }

    useEffect(() => {
        setOriginalProduct(undefined);
        setProduct(undefined);
    }, [])

    useEffect(() => {
        if (pageType === 'new') {
            setOriginalProduct(new ProductBuilder().New());
            setProduct(new ProductBuilder().New());
        } else {
            if (handle === undefined) return;
            fetchProduct(handle);
        }
    }, [handle, searchParams, pageType]);

    useEffect(() => {
        if (originalProduct === undefined) return;
        if (product === undefined) return;
        const diffs = diff(originalProduct, product);
        setHasChanged(diffs.length > 0 || originalProduct.metafields !== JSON.stringify(metafields));
        console.log(product.metafields, metafields);
    }, [originalProduct, product, metafields])

    useEffect(() => {
        const pic = productImageChange.subscribe((fileChange: FileChangeEvent) => {
            setNewProductImage(fileChange);
            if (product === undefined) return;
            if (fileChange.file.length < 1) return;
            ProductService.UploadImage(fileChange.file[0], product.id, {
                success: (_: ProductEntity) => {
                    fetchProduct(_.handle);
                    setNewProductImage(undefined);
                },
                error: (err: any) => {
                    console.error(err);
                }
            })
        });

        return () => {
            productImageChange.unsubscribe(pic);
        }
    }, [product, productImageChange]);

    const DeleteProductImageHandler = useCallback((media: string) => {
        if (product === undefined) return;
        ProductService.DeleteImage(product.id, media, {
            success: function (_: ProductEntity): void {
                setProduct({ ..._ });
            },
            error: function (err: any): void {
                console.log(err);
            }
        })
    }, [product, setProduct]);

    const OrderChangeHandler = useCallback((media: ProductMedia) => {
        if (product === undefined || originalProduct === undefined) return;
        const tempMedia = [...product.media]
        const indexToUpdate = tempMedia.findIndex(m => m.url === media.url);
        if (indexToUpdate === -1) { console.error('media with url not found'); return; }   // media not found?
        if (tempMedia[indexToUpdate].order === media.order) { console.error('media order not changed'); return; }
        tempMedia[indexToUpdate] = { ...media }
        setProduct({ ...product, media: [...tempMedia] })
        setOriginalProduct({ ...product, media: [...tempMedia] })
        ProductService.UpdateMedia(tempMedia, originalProduct.id, {
            success: (_: ProductEntity) => {
                console.log('updated', _.media);
            },
            error: (err: any) => {
                alert('failed to update media order');
                console.error(err);
            }
        });
    }, [product, originalProduct]);

    const DeleteProductHandler = useCallback(() => {
        if (originalProduct === undefined) return;
        // eslint-disable-next-line no-restricted-globals
        const res = confirm('ARE YOU SURE?');
        if (res) {
            ProductService.DeleteProduct(originalProduct.id, {
                success: function (_: any): void {
                    navigation('/admin?tab=products');
                },
                error: function (err: any): void {
                    alert('failed to delete product');
                    console.error(err);
                }
            })
        }
    }, [navigation, originalProduct]);

    const SaveProductHandler = useCallback(() => {
        if (originalProduct === undefined || product === undefined) return;
        if (!hasChanged) return;
        console.log(product);
        const request = {
            title: product.title,
            description: product.description,
            HTMLdescription: product.HTMLdescription,
            externalRef: product.externalRef,
            available: product.available,
            price: product.price,
            options: product.options,
            postageProvider: product.postageProvider,
            brand: product.brand,
            metafields: JSON.stringify(metafields)
        };
        if (pageType === 'new') {
            // create
            ProductService.CreateProduct(request, {
                success: (_: ProductEntity) => {
                    // route to new product
                    navigation(`/admin/products/edit/${_.handle}?isNew=true`);
                },
                error: (err: any) => {
                    console.error("Failed to create product", err);
                }
            })
        } else {
            // update
            ProductService.SaveProduct(originalProduct.id, request, {
                success: (_: ProductEntity) => {
                    setOriginalProduct({ ..._ });
                    setProduct({ ..._ });
                },
                error: (err: any) => {
                    console.error("Failed to save product", err);
                }
            })
        }
    }, [originalProduct, product, hasChanged, metafields, pageType, navigation])

    const SaveOptionHandler = useCallback(() => {
        if (product === undefined || newOption === undefined) return;
        setProduct({ ...product, options: [...product.options, newOption] })
        setNewOption(undefined);
    }, [product, newOption])

    return <>
        {product !== undefined &&
            <>
                <div className="mt-4 container mx-auto px-0">
                    {isNew &&
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-between py-2 mb-2 bg-success">
                                <h3>Product created successfully!</h3>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between py-2 mb-2">
                            <div className="d-flex align-items-center justify-content-between gap-2">
                                <button className="bg-transparent border-0" onClick={() => navigation('/admin?tab=products')}>
                                    <FaChevronLeft />
                                </button>

                                <h3 className="my-0">{pageType === 'new' ? 'New Product' : product.title}</h3>
                            </div>

                            <div className={`d-flex align-items-center justify-content-between gap-2`}>
                                {pageType !== 'new' && <StatefullInput stateId={"brand"} label={null} defaultValue={product.brand} inputType={"select"} autocompleteType={""} options={[{ view: 'Flamme', value: 'flamme' }, { view: 'Flameko', value: 'flameko' }]} onChangeCallback={(_: string) => {
                                    setProduct({ ...product, brand: _ });
                                }} /> }
                                
                                {pageType !== 'new' && <button onClick={() => window.open(`/product/${product.handle}`, '_blank')} className="btn btn-outline-secondary me-2">View</button>}
                                <button onClick={() => SaveProductHandler()} disabled={!hasChanged} className={`btn ${hasChanged ? 'btn-primary' : 'btn-outline-secondary'}`}>Save</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-8 bg-white px-4 py-3 rounded-lg">
                            <StatefullInput stateId={"title"} label={"Title"} defaultValue={product.title} inputType={"text"} autocompleteType={"none"}
                                onChangeCallback={(_: string) => {
                                    setProduct({ ...product, title: _ });
                                }} />

                            <StatefullInput classes="mt-4" stateId={"description"} label={"Description"} defaultValue={product.description} inputType={"textarea"} autocompleteType={"none"}
                                onChangeCallback={(_: string) => {
                                    setProduct({ ...product, description: _ });
                                }} />

                            <StatefullInput classes="mt-4" stateId={"htmlDescription"} label={"HTML Description"} defaultValue={product.HTMLdescription} inputType={"textarea"} autocompleteType={"none"}
                                onChangeCallback={(_: string) => {
                                    setProduct({ ...product, HTMLdescription: _ });
                                }} />

                            <div>
                                <StatefullInput classes="mt-4" stateId={"metafields"} label={"Meta Description"} defaultValue={metafields === undefined ? '' : metafields.description} inputType={"textarea"} autocompleteType={"none"}
                                    onChangeCallback={(_: string) => {
                                        setMetafields({ description: _ });
                                    }} />
                            </div>

                            <div className="border-top mt-4 pb-4"></div>

                            <h4>Product Media <small>(excluding featured image)</small></h4>

                            <div className="d-flex align-items-stretch justify-content-start gap-2 flex-wrap">
                                {product.media.sort((a, b) => a.order - b.order).map((media: ProductMedia, i: number) => {
                                    return <div key={`${media.url}-${i}`} className="">
                                        <AdminProductImage deleteHandler={DeleteProductImageHandler} orderChangeHandler={OrderChangeHandler} product={product} mediaItem={media} />
                                    </div>;
                                })}
                            </div>

                            <div className="border-top mt-4 pb-4"></div>

                            <h4>Product Options</h4>

                            <div>
                                {product.options.map((po, i) => {
                                    return <div key={`${po.optionHandle}-${i}`} className="d-flex align-items-start justify-content-between gap-2 mb-4">
                                        <details className={`w-100`}>
                                            <summary className="bg-light py-2 px-3 rounded-3"><p className="fs-md-20 d-block fw-bolder mb-0">{po.optionName}</p></summary>
                                            <div className="border px-3 pb-3 border-1 mt-2 rounded-3">
                                                {po.options.map((o, i2) => {
                                                    return <div key={`${o.handle}-${i2}`} className={`d-flex align-items-center justify-content-start gap-1 ${i2 === 0 ? 'mt-2' : 'mt-1'}`}>
                                                        <StatefullInput classes="flex-fill w-100" stateId={""} label={`Available Option ${i2 + 1}`} defaultValue={o.viewName} inputType={"text"} readOnly={true} autocompleteType={"none"}
                                                            onChangeCallback={(_: string) => {
                                                                // setProduct({ ...product, HTMLdescription: _ });
                                                            }} />
                                                        <div>
                                                            <div style={{ height: 24 }}></div>
                                                            <button onClick={() => {
                                                                const tempOptions = JSON.parse(JSON.stringify([...product.options]));
                                                                tempOptions[i].options.splice(i2, 1);
                                                                setProduct({ ...product, options: [...tempOptions] });
                                                            }} className="btn btn-danger"><FaTrash /></button>
                                                        </div>
                                                    </div>;
                                                })}

                                                {newOptionValue !== undefined && <div className={`d-flex align-items-center justify-content-start gap-1 mt-2`}>
                                                    <StatefullInput classes="flex-fill w-100" stateId={""} label={`New Available Option`} defaultValue={''} inputType={"text"} autocompleteType={"none"}
                                                        onChangeCallback={(_: string) => {
                                                            setNewOptionValue({ ...newOptionValue, viewName: _, handle: HandleHelper.Handle(_) })
                                                        }} />
                                                    <div>
                                                        <div style={{ height: 24 }}></div>
                                                        <button onClick={() => {
                                                            const tempOptions = JSON.parse(JSON.stringify([...product.options]));
                                                            const updatedIndex = [...product.options].findIndex(_po => _po.optionHandle === po.optionHandle);
                                                            tempOptions[updatedIndex].options.push({ ...newOptionValue });
                                                            setProduct({ ...product, options: [...tempOptions] })
                                                            setNewOptionValue(undefined);
                                                        }} className="btn btn-primary"><FaFloppyDisk /></button>
                                                    </div>
                                                </div>}

                                                {newOptionValue === undefined && <div>
                                                    <div style={{ height: 24 }}></div>
                                                    <button className="btn btn-outline-primary d-flex align-items-center" onClick={() => {
                                                        setNewOptionValue(new ProductOption())
                                                    }}
                                                    ><span className="me-2">New Option Value</span><FaPlus /></button>
                                                </div>}
                                                {newOptionValue !== undefined && <button className={`mt-2 btn btn-outline-warning`} onClick={() => setNewOptionValue(undefined)}>Cancel New Option Value</button>}
                                            </div>
                                        </details>

                                        <div>
                                            <button className="btn btn-danger" onClick={() => {
                                                const tempOptions = JSON.parse(JSON.stringify([...product.options])) as ProductOptions[];
                                                const indexToRemove = [...product.options].findIndex(_po => _po.optionHandle === po.optionHandle);
                                                tempOptions.splice(indexToRemove, 1);
                                                setProduct({ ...product, options: [...tempOptions] });
                                            }}><FaTrash /></button>
                                        </div>
                                    </div>
                                })}

                                {newOption !== undefined && <div className="mt-4 d-flex align-items-center gap-1">
                                    <StatefullInput classes="w-100 flex-fill" stateId={"title"} label={"New Option Type"} defaultValue={''} inputType={"text"} autocompleteType={"none"}
                                        onChangeCallback={(_: string) => {
                                            setNewOption({ ...newOption, optionName: _, optionHandle: HandleHelper.Handle(_), options: [] });
                                        }} />
                                    <div>
                                        <div style={{ height: 24 }}></div>
                                        <button className="btn btn-primary" onClick={() => SaveOptionHandler()}><FaFloppyDisk /></button>
                                    </div>
                                </div>}

                                {newOption === undefined && <div>
                                    <button className="btn btn-outline-primary d-flex align-items-center" onClick={() => {
                                        setNewOption(new ProductOptions())
                                    }}
                                    ><span className="me-2">New Option</span><FaPlus /></button>
                                </div>}

                                {newOption !== undefined && <button className={`mt-2 btn btn-outline-warning`} onClick={() => setNewOption(undefined)}>Cancel New Option</button>}
                            </div>
                        </div>
                        <div className="col-12 col-md-4 bg-white px-4 py-3 rounded-lg">
                            <StatefullInput stateId={"available"} label={"Availability"} defaultValue={product.available ? 'active' : 'inactive'} inputType={"select"} autocompleteType={""} options={[{ view: 'Active', value: 'active' }, { view: 'Inactive', value: 'inactive' }]} onChangeCallback={(_: string) => {
                                setProduct({ ...product, available: _ === 'active' });
                            }} />

                            <StatefullInput classes="mt-4" stateId={"available"} label={"Postage Provider"} defaultValue={product.postageProvider} inputType={"select"} autocompleteType={""} options={[{ view: 'UPS', value: 'UPS' }, { view: 'AXL', value: 'AXL' }]} onChangeCallback={(_: string) => {
                                setProduct({ ...product, postageProvider: _ });
                            }} />

                            <StatefullInput classes="mt-4" stateId={"externalRef"} label={"External Ref / SKU"} defaultValue={product.externalRef} inputType={"text"} autocompleteType={"none"}
                                onChangeCallback={(_: string) => {
                                    setProduct({ ...product, externalRef: _ });
                                }} />

                            {pageType !== 'new' &&
                                <div>
                                    <StatefullFileSelect inputKey={product.id} accept={'image/*'} classes="mt-4 w-100" label={'Select Image'} fileSelected={productImageChange} />
                                    <div className="border border-dark rounded-lg overflow-hidden">
                                        {product.featuredMedia && <img className='w-100 d-block' alt={product.title} src={product.featuredMedia} />}
                                        {(product.featuredMedia === undefined || product.featuredMedia === '' || product.featuredMedia === null) && <div className="d-flex align-items-between justify-content-center py-4"><p className="my-0">No Image</p></div>}
                                    </div>
                                    {newProductImage === undefined ? <></> : <button className="btn btn-outline-warning w-100 mt-1" onClick={() => {
                                        setNewProductImage(undefined);
                                        setProduct({ ...product, featuredMedia: originalProduct?.featuredMedia || '' });
                                    }}><FaRotateLeft /> Revert to original</button>}
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {pageType !== 'new' && <>
                    <AdminVariantEditor product={product} />

                    <div className="mt-5 mb-5 container mx-auto px-0">
                        <div className="d-flex align-items-center justify-content-end">
                            <button onClick={() => DeleteProductHandler()} disabled={originalProduct === undefined || originalProduct.id === undefined} className={`btn btn-danger`}>Delete Product</button>
                        </div>
                    </div>
                </>
                }
            </>
        }
    </>;
}