import { HTTP_CONSTANTS } from "../../constants/http-constants";
import CheckoutRequest, { CheckoutValidator } from "../../models/requests/CheckoutRequest";
import { Order } from "../../models/responses/CheckoutResponse";
import CheckoutResponse from "../../models/responses/CheckoutResponse";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";
import Cookies from "js-cookie";
import { CartItem } from "./cart-service";
import { environment } from "../../environment/environment";

/* KLARNA */

function CreateKlarnaSession(request: CheckoutRequest,
    callback: ResponseCallback<any>): void {
    HttpService.post<CheckoutRequest, CheckoutResponse>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/klarna/create-session`,
        request,
        (resp: CheckoutResponse) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function CreateKlarnaOrder(request: CheckoutRequest, token: string, ref: string,
    callback: ResponseCallback<any>): void {
    HttpService.post<CheckoutRequest, CheckoutResponse>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/klarna/complete-order/${token}/${ref}`,
        request,
        (resp: CheckoutResponse) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

/* TP */

function Checkout(
    request: CheckoutRequest,
    callback: ResponseCallback<CheckoutResponse>
): void {
    HttpService.post<CheckoutRequest, CheckoutResponse>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/checkout`,
        CheckoutValidator.Build(CheckoutValidator.Sign(request)),
        (resp: CheckoutResponse) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function Complete3DS(
    request: { threeDSRef: string, threeDSMethodData: string },
    callback: ResponseCallback<CheckoutResponse>
): void {
    HttpService.post<{ threeDSRef: string, threeDSMethodData: string }, CheckoutResponse>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/checkout/3ds/validate`,
        request,
        (resp: CheckoutResponse) => {
            if (resp.status === '3ds') {
                // we need to redo 3ds
                console.log('set', resp.threeDSRef)
                Cookies.set('3dsref', resp.threeDSRef, { domain: window.location.hostname.replace('www', '') });
                // Cookies.set('3dsref', resp.threeDSRef, { domain: window.location.hostname.replace('www.', '') });
                window.setTimeout(() => {
                    callback.success(resp);
                }, 100);
            } else {
                console.log('set', resp.threeDSRef)
                Cookies.set('3dsref', resp.threeDSRef, { domain: window.location.hostname.replace('www', '') });
                // Cookies.set('3dsref', resp.threeDSRef, { domain: window.location.hostname.replace('www.', '') });
                window.setTimeout(() => {
                    callback.success(resp);
                }, 100);
            }
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function AuthTransaction(cres: string, callback: ResponseCallback<Order>): void {
    var threeDSRef = Cookies.get("3dsref");
    //var threeDSRef = localStorage.getItem('3dsref');
    if (threeDSRef === null || threeDSRef === undefined) { callback.error('No 3DS Ref'); return; }
    HttpService.post<{ threeDSRef: string, cres: string }, Order>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/checkout/authorise`,
        { threeDSRef: threeDSRef, cres: cres },
        (resp: Order) => {
            console.log("REMOVE 3DS REF COOKIE");
            Cookies.remove('3dsref', { domain: window.location.hostname.replace('www', '') })
            // Cookies.remove('3dsref', { domain: window.location.hostname.replace('www.', '') })
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function GetTransaction(id: string, callback: ResponseCallback<Order>): void {
    HttpService.get<Order>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/checkout/order/${id}`,
        (resp: Order) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function Log(log: any): void {
    HttpService.post<any, any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/checkout/custom-log`,
        log,
        (resp: any) => {
        },
        (error: HttpError) => {
        }
    );
}

const shippingMatrix: any = {
    'UPS': {
        'BT': 1500,
        'IM': 2500,
        'KW': 2500,
        'HS': 2500,
        'IV': 2500,
        'ZE': 2500,
        'JE': 2500,
        'GY': 2500
    },
    'AXL': {
        // OVERRIDES
        'PA41': 10000,
        'PA42': 10000,
        'PA43': 10000,
        'PA44': 10000,
        'PA45': 10000,
        'PA46': 10000,
        'PA47': 10000,
        'PA48': 10000,
        'PA49': 10000,
        'PA50': 10000,
        'PA51': 10000,
        'PA52': 10000,
        'PA53': 10000,
        'PA54': 10000,
        'PA55': 10000,
        'PA56': 10000,
        'PA57': 10000,
        'PA58': 10000,
        'PA59': 10000,
        'PA60': 10000,
        'PA61': 10000,
        'PA62': 10000,
        'PA63': 10000,
        'PA64': 10000,
        'PA65': 10000,
        'PA66': 10000,
        'PA67': 10000,
        'PA68': 10000,
        'PA69': 10000,
        'PA70': 10000,
        'PA71': 10000,
        'PA72': 10000,
        'PA73': 10000,
        'PA74': 10000,
        'PA75': 10000,
        'PA76': 10000,
        'PA77': 10000,
        'PA78': 10000,
        'PA79': 10000,
        'PA80': 10000,
        'KW15': 10000,
        'KW16': 10000,
        'KW17': 10000,
        'IV40': 10000,
        'IV41': 10000,
        'IV42': 10000,
        'IV43': 10000,
        'IV44': 10000,
        'IV45': 10000,
        'IV46': 10000,
        'IV47': 10000,
        'IV48': 10000,
        'IV49': 10000,
        'IV50': 10000,
        'IV51': 10000,
        'IV52': 10000,
        'IV53': 10000,
        'IV54': 10000,
        'IV55': 10000,
        'IV56': 10000,
        'PA20': 10000,
        'PA28': 10000,
        'PH42': 10000,
        'PH43': 10000,
        'PH44': 10000,
        'ZE1': 10000,
        'ZE2': 10000,
        'ZE3': 10000,
        'KA27': 10000,
        'KA28': 10000,
        
        // DEFAULT
        'AB': 8500,
        'FK': 8500,
        'IV': 8500,
        'KW': 8500,
        'PA': 8500,
        'PH': 8500,
        'BT': 13500,
        'JE': 10000,
        'GY': 10000,
        'TR': 10000,
        'HS': 10000,
        'DD': 8500,
        'KA': 8500,
        'KY': 8500,
        'G52': 8500,
        'TD': 8500,
        'ZE': 8500,
        'IM': 10000,
    }
}

function GetShippingPrice(shippingZIP: string, cartItem: CartItem): number {
    shippingZIP = shippingZIP.toUpperCase();
    const companyList = shippingMatrix[cartItem.variant.postageProvider];
    if (companyList === undefined) return 0;    // no provider - will fall over serverside
    var shippingZip = Object.keys(companyList).find(zipStart => shippingZIP.startsWith(zipStart))
    if (shippingZip === undefined) return 0;    // no provider - will fall over serverside
    return companyList[shippingZip];
}

function GetOrderTotal(shippingZIP: string, cartItem: CartItem[]): { total: number, compareAt: number } {
    if (cartItem.length === 0) return { total: 0, compareAt: 0 };
    const products = cartItem.map(ci => ci.variant.price * ci.quantity).reduce((p, c) => p + c);
    const shipping = cartItem.map(ci => GetShippingPrice(shippingZIP, ci)).reduce((p, c) => p + c);
    const total = Number.parseInt((products + shipping).toFixed(2));
    const discount = Number.parseFloat((products * environment.discount).toFixed(2));
    return { total: Number.parseInt((total - discount).toString()), compareAt: total };
}

const CheckoutService = {
    Log,

    // KLARNA
    CreateKlarnaSession,
    CreateKlarnaOrder,

    // TP
    Checkout,
    Complete3DS,
    AuthTransaction,
    GetShippingPrice,
    GetOrderTotal,
    GetTransaction
};

export default CheckoutService;
