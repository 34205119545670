import ProductEntity from "./product";

export default class CollectionEntity {
    id: string;
    title: string;
    handle: string;
    brand: string;
    products: ProductEntity[]
}

export class CollectionBuilder {
    public New(): CollectionEntity {
        const collection = new CollectionEntity();
        collection.products = []
        return collection;
    }
}