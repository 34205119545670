import { useEffect, useState } from "react";
import StatefullFileSelect, { FileChangeEvent } from "../statefull-file-select/statefull-file-select";
import EventEmitter from "../../services/event/event-emitter";
import AdminService from "../../services/ui/admin-service";
import { FaClipboard } from "react-icons/fa6";

export default function AdminInstructionUpload() {

    const [fileChange] = useState<EventEmitter<FileChangeEvent>>(new EventEmitter<FileChangeEvent>());
    const [files, setFiles] = useState<any[]>([]);

    useEffect(() => {
        AdminService.GetInstructions({
            success: (_: any[]) => {
                setFiles(_);
            },
            error: function (err: any): void {
                console.error("Error fetching images" + err);
            }
        });
    }, [])

    useEffect(() => {
        const pic = fileChange.subscribe((fileChange: FileChangeEvent) => {
            if (fileChange.file.length < 1) return;
            AdminService.UploadStaticFile(fileChange.file[0], {
                success: (_: string) => {
                    setFiles([...files, _])
                },
                error: (err: any) => {
                    console.error(err);
                }
            })
        });

        return () => {
            fileChange.unsubscribe(pic);
        }
    }, [fileChange, files]);

    return <>
        <div>
            <StatefullFileSelect inputKey={""} accept={'.pdf'} classes="w-100" label={'Upload New Instruction'} fileSelected={fileChange} />
            <div className="d-flex align-items-stretch justify-content-start gap-4 flex-wrap mt-4">
                {files.map((file, i) => {
                    return <div key={`file-${i}`} style={{ maxWidth: 400 }} className="w-100 d-flex flex-column gap-1 border rounded-3 overflow-hidden">
                        <div className="bg-light p-2 flex-fill d-flex align-items-center">
                            <p className={'my-2'}>{file.substring(file.lastIndexOf('_') + 1)}</p>
                        </div>
                        <div className="p-2">
                            <button onClick={() => navigator.clipboard.writeText(file)} className="w-100 btn btn-outline-primary d-flex align-items-center justify-content-center gap-3"><span className="d-block my-0">Copy Link</span><FaClipboard /></button>
                            <textarea readOnly={true} className="w-100 form-control mt-4" value={`<a style="color: #0D6EFD; cursor: pointer; text-decoration: underline;" href="${file}">View</a>`}></textarea>
                            <button onClick={() => navigator.clipboard.writeText(`<a style="color: #0D6EFD; cursor: pointer; text-decoration: underline;" href="${file}">View</a>`)} className="mt-2 w-100 btn btn-outline-primary d-flex align-items-center justify-content-center gap-3"><span className="d-block my-0">Copy HTML</span><FaClipboard /></button>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </>
}