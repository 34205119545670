import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import JSConfetti from "js-confetti";
import Theme from "./misc/theme/theme";
import LandingPage from "./pages/landing-page/landing-page";
import CollectionPage from "./pages/collection-page/collection-page";
import ProductPage from "./pages/product-page/product-page";
import CheckoutPage from "./pages/checkout-page/checkout-page";
import CartPage from "./pages/cart-page/cart-page";
import CheckoutComplete from "./pages/checkout-complete/checkout-complete";
import AdminPage from "./pages/admin/admin-page";
import UserService from "./services/session/user-service";
import { AccountType } from "./models/data/user-session";
import RegisterPage from "./pages/authentication/register-page";
import LoginPage from "./pages/authentication/login-page";
import AdminProductPage from "./components/admin-pages/admin-product-page";
import AdminCollectionPage from "./components/admin-pages/admin-collection-page";
import OurAdvantagesPage from "./pages/misc/our-advantages";
import AccountPage from "./pages/account/account-page";
import PrivacyPolicyPage from "./pages/misc/privacy-policy";
import TermsAndConditionsPage from "./pages/misc/terms-and-conditions";
import FaqPage from "./pages/misc/faqs";
import ThankYouPage from "./pages/thank-you-page/thank-you-page";
import AdminOrderPage from "./components/admin-pages/admin-order-page";
import ProductsPage from "./pages/products-page/products-page";

declare global {
	interface Window {
		confetti: any;
	}
}

function App() {
	window.confetti = new JSConfetti();

	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(UserService.getCurrentSession() !== null);
	const [isAdmin, setIsAdmin] = useState<boolean>(UserService.getCurrentSession()?.accountType === AccountType.Admin);

	useEffect(() => {
		var sub = UserService.sessionChangeEvent.subscribe(() => {
			console.log('current session changed')
			setIsLoggedIn(UserService.getCurrentSession() !== null)
			setIsAdmin(UserService.getCurrentSession()?.accountType === AccountType.Admin);
		});

		return () => {
			UserService.sessionChangeEvent.unsubscribe(sub);
		};
	}, [])

	useEffect(() => {
		setIsLoggedIn(UserService.getCurrentSession() !== null)
		setIsAdmin(UserService.getCurrentSession()?.accountType === AccountType.Admin);
	}, [])

	useEffect(() => {
		console.log('session change', isLoggedIn, isAdmin);
		if (!isAdmin && window.location.pathname.indexOf('/admin') > -1) window.location.href = `${window.location.origin}`;
		if (!isLoggedIn && window.location.pathname.indexOf('/account') > -1) window.location.href = `${window.location.origin}`;
		if (isLoggedIn && window.location.pathname.indexOf('/login') > -1) window.location.href = `${window.location.origin}`;
		if (isLoggedIn && window.location.pathname.indexOf('/register') > -1) window.location.href = `${window.location.origin}`;
	}, [isAdmin, isLoggedIn]);

	return (
		<>
			<Router>
				<Routes>
					<Route path="*" element={<Navigate replace to="/" />} />

					<Route path="/" element={<Theme />}>
						<Route index element={<LandingPage />}></Route>

						<Route path="collections" element={<CollectionPage />}></Route>
						<Route path="collections/:handle" element={<CollectionPage />}></Route>

						<Route path="products" element={<ProductsPage />}></Route>

						<Route path="product/:handle" element={<ProductPage />}></Route>
						<Route path="product/:handle/:variantSKU" element={<ProductPage />}></Route>

						<Route path="cart" element={<CartPage />}></Route>
						<Route path="checkout">
							<Route index element={<CheckoutPage status={'new'} />}></Route>
							{/* Pulls the order, navigates to the correct page */}
							<Route path="complete" element={<CheckoutComplete />}></Route>
							<Route path="declined" element={<CheckoutPage status={'declined'} />}></Route>
							<Route path="thank-you/:orderId" element={<ThankYouPage />}></Route>
						</Route>

						{/* MISC */}
						<Route path="pages">
							<Route path="our-advantages" element={<OurAdvantagesPage />} />
							<Route path="privacy-policy" element={<PrivacyPolicyPage />} />
							<Route path="terms-and-conditions" element={<TermsAndConditionsPage />} />
							<Route path="faqs" element={<FaqPage />} />
						</Route>

						{!isLoggedIn && <>
							<Route path="login" element={<LoginPage />}></Route>
							<Route path="register" element={<RegisterPage />}></Route>
						</>
						}

						{isLoggedIn && <>
							<Route path="account">
								<Route index element={<AccountPage />}></Route>
							</Route>
						</>
						}

						{isLoggedIn && isAdmin && <>
							<Route path="admin">
								<Route index element={<AdminPage />}></Route>
								<Route path="products/edit/:handle" element={<AdminProductPage pageType={'edit'} />}></Route>
								<Route path="products/create" element={<AdminProductPage pageType={'new'} />}></Route>

								<Route path="collections/edit/:handle" element={<AdminCollectionPage pageType={'edit'} />}></Route>
								<Route path="collections/create" element={<AdminCollectionPage pageType={'new'} />}></Route>

								<Route path="orders/view/:id" element={<AdminOrderPage />}></Route>
							</Route>
						</>
						}
					</Route>

					{/* REDIRECTS */}
					<Route path="/Best-Deals/" element={<Navigate replace to="/collections/popular-products" />} />
					<Route path="/New-Arrivals/" element={<Navigate replace to="/collections/new-arrivals" />} />
					<Route path="/account/forgot.html" element={<Navigate replace to="/login/" />} />
					<Route path="/account/sign-up.html" element={<Navigate replace to="/login/" />} />
					<Route path="/collections/electric-fireplace-suite" element={<Navigate replace to="/collections/media-wall-fires/" />} />
					<Route path="/collections/electric-insert-fire" element={<Navigate replace to="/collections/electric-insert-fires/" />} />
					<Route path="/collections/electric-stove" element={<Navigate replace to="/collections/electric-stoves/" />} />
					<Route path="/collections/fireplace-accessories" element={<Navigate replace to="/collections/accessories/" />} />
					<Route path="/collections/wall-mounted-electric-fire" element={<Navigate replace to="/collections/wall-mounted-electric-fires/" />} />
					<Route path="/pages/faq" element={<Navigate replace to="/pages/faqs/" />} />
					<Route path="/products/16-portable-basket-electric-fire" element={<Navigate replace to="/product/portable-flamme-16-basket-fire/" />} />
					<Route path="/products/23-portable-basket-electric-fire" element={<Navigate replace to="/product/flamme-23-58cm-portable-basket-electric-fire-birch/" />} />
					<Route path="/products/4pcs-transducer-discs-only" element={<Navigate replace to="/collections/accessories" />} />
					<Route path="/products/ashbourne-32-expresso-oak" element={<Navigate replace to="/product/flamme-ashbourne-fireplace-with-32-surround/EF321A-MT321C-EO/" />} />
					<Route path="/products/ashbourne-32-natural-oak" element={<Navigate replace to="/product/flamme-ashbourne-fireplace-with-32-surround/EF321A-MT321C-NO/" />} />
					<Route path="/products/ashbourne-32-white" element={<Navigate replace to="/product/flamme-ashbourne-fireplace-with-32-surround/EF321A-MT321C-W/" />} />
					<Route path="/products/beaumont-48-espresso-oak-electric-fireplace-suites" element={<Navigate replace to="/product/flamme-beaumont-48-electric-fireplace-suite/EF321A-MT321D-EO/" />} />
					<Route path="/products/beaumont-48-white-electric-fireplace-suites" element={<Navigate replace to="/product/flamme-beaumont-48-electric-fireplace-suite/EF321A-MT321D-W/" />} />
					<Route path="/products/beaumont-48-white-electric-fireplace-suites-1" element={<Navigate replace to="/product/flamme-beaumont-48-electric-fireplace-suite/EF321A-MT321D-CG/" />} />
					<Route path="/products/bluetooth-speaker" element={<Navigate replace to="/product/flamme-bluetooth-speaker/" />} />
					<Route path="/products/carmaux-55-electric-fireplace-suites-cement-grey" element={<Navigate replace to="/product/flamme-carmaux-55-141cm-freestanding-electric-fireplace-suite/EM172D-MT172F-CG/" />} />
					<Route path="/products/carmaux-55-electric-fireplace-suites-white" element={<Navigate replace to="/product/flamme-carmaux-55-141cm-freestanding-electric-fireplace-suite/EM172D-MT172F-W/" />} />
					<Route path="/products/casella-43-espresso-oak-electric-fireplace-suites" element={<Navigate replace to="/product/flamme-casella-electric-fireplace-with-43-surround-/EF379A-MT379C-EO/" />} />
					<Route path="/products/casella-43-natural-oak-electric-fireplace-suites" element={<Navigate replace to="/product/flamme-casella-electric-fireplace-with-43-surround-/EF379A-MT379C-NO/" />} />
					<Route path="/products/castello-media-wall-inset-electric-fireplace" element={<Navigate replace to="/product/flamme-33-84cm-castello-slim-frame-recessed-media-wall-insert/EF385B/" />} />
					<Route path="/products/castello-media-wall-inset-electric-fireplace-1" element={<Navigate replace to="/product/flamme-33-84cm-castello-slim-frame-recessed-media-wall-insert/EF286B/" />} />
					<Route path="/products/castello-media-wall-inset-electric-fireplace-2" element={<Navigate replace to="/product/flamme-33-84cm-castello-slim-frame-recessed-media-wall-insert/EF287B/" />} />
					<Route path="/products/castello-media-wall-inset-electric-fireplace-3" element={<Navigate replace to="/product/flamme-33-84cm-castello-slim-frame-recessed-media-wall-insert/EF288B/" />} />
					<Route path="/products/castello-media-wall-inset-electric-fireplace-4" element={<Navigate replace to="/product/flamme-33-84cm-castello-slim-frame-recessed-media-wall-insert/EF289B/" />} />
					<Route path="/products/electric-baskets-fire" element={<Navigate replace to="/product/flamme-16-basket-fire-/" />} />
					<Route path="/products/eucla-42-natural-oak" element={<Navigate replace to="/product/flamme-ecula-fireplace-with-43-surround/EF378A-MT378C-W/" />} />
					<Route path="/products/everleigh-44-espresso-oak" element={<Navigate replace to="/product/everleigh-44-freestanding-electric-fireplace/MT321A-EO-EF321A/" />} />
					<Route path="/products/everleigh-44-white" element={<Navigate replace to="/product/everleigh-44-freestanding-electric-fireplace/MT321A-W-EF321A/" />} />
					<Route path="/products/evora-39-espresso-oak-1" element={<Navigate replace to="/product/evora-fireplace-with-39-100cm-47-120cm-surround-2-sizes-3-colour-options/EF378A-MT378A-EO/" />} />
					<Route path="/products/evora-39-natural-oak-2" element={<Navigate replace to="/product/evora-fireplace-with-39-100cm-47-120cm-surround-2-sizes-3-colour-options/EF378A-MT378A-NO/" />} />
					<Route path="/products/evora-39-white-1" element={<Navigate replace to="/product/evora-fireplace-with-39-100cm-47-120cm-surround-2-sizes-3-colour-options/EF378A-MT378A-W/" />} />
					<Route path="/products/evora-47-espresso-oak" element={<Navigate replace to="/product/evora-fireplace-with-39-100cm-47-120cm-surround-2-sizes-3-colour-options/" />} />
					<Route path="/products/evora-47-natural-oak" element={<Navigate replace to="/product/evora-fireplace-with-39-100cm-47-120cm-surround-2-sizes-3-colour-options/" />} />
					<Route path="/products/evora-47-white" element={<Navigate replace to="/product/evora-fireplace-with-39-100cm-47-120cm-surround-2-sizes-3-colour-options/" />} />
					<Route path="/products/flamme-37-beadell-electric-fireplace-suite" element={<Navigate replace to="/product/flamme-beadell-electric-fireplace-suite/" />} />
					<Route path="/products/flamme-37-beadell-electric-fireplace-suite-1" element={<Navigate replace to="/product/flamme-beadell-electric-fireplace-suite/" />} />
					<Route path="/products/flamme-37-beadell-electric-fireplace-suite-2" element={<Navigate replace to="/product/flamme-beadell-electric-fireplace-suite/" />} />
					<Route path="/products/flamme-39-beadell-electric-fireplace-suite" element={<Navigate replace to="/product/flamme-beadell-electric-fireplace-suite/" />} />
					<Route path="/products/flamme-39-beadell-electric-fireplace-suite-1" element={<Navigate replace to="/product/flamme-beadell-electric-fireplace-suite/" />} />
					<Route path="/products/flamme-39-beadell-electric-fireplace-suite-2" element={<Navigate replace to="/product/flamme-beadell-electric-fireplace-suite/" />} />
					<Route path="/products/flamme-43-electric-fireplace-suite" element={<Navigate replace to="/product/flamme-43-51-lipson-electric-fireplace-suite-2-sizes-4-colours/" />} />
					<Route path="/products/flamme-43-electric-fireplace-suite-1" element={<Navigate replace to="/product/flamme-43-51-lipson-electric-fireplace-suite-2-sizes-4-colours/" />} />
					<Route path="/products/flamme-43-electric-fireplace-suite-2" element={<Navigate replace to="/product/flamme-43-51-lipson-electric-fireplace-suite-2-sizes-4-colours/" />} />
					<Route path="/products/flamme-51-electric-fireplace-suite" element={<Navigate replace to="/product/flamme-43-51-lipson-electric-fireplace-suite-2-sizes-4-colours/" />} />
					<Route path="/products/flamme-51-electric-fireplace-suite-1" element={<Navigate replace to="/product/flamme-43-51-lipson-electric-fireplace-suite-2-sizes-4-colours/" />} />
					<Route path="/products/flamme-51-electric-fireplace-suite-2" element={<Navigate replace to="/product/flamme-43-51-lipson-electric-fireplace-suite-2-sizes-4-colours/" />} />
					<Route path="/products/flamme-knighton-wall-mounted-fireplace" element={<Navigate replace to="/product/knighton-43-110cm-51-130cm-3-sided-electric-free-standing-wall-mounted-fireplace/" />} />
					<Route path="/products/flamme-knighton-wall-mounted-fireplace-1" element={<Navigate replace to="/product/knighton-43-110cm-51-130cm-3-sided-electric-free-standing-wall-mounted-fireplace/" />} />
					<Route path="/products/flamme-knighton-wall-mounted-fireplace-2" element={<Navigate replace to="/product/knighton-43-110cm-51-130cm-3-sided-electric-free-standing-wall-mounted-fireplace/" />} />
					<Route path="/products/flamme-knighton-wall-mounted-fireplace-3" element={<Navigate replace to="/product/knighton-43-110cm-51-130cm-3-sided-electric-free-standing-wall-mounted-fireplace/" />} />
					<Route path="/products/flamme-mardella-fireplace-1" element={<Navigate replace to="/product/mardella-40-freestanding-fireplace/" />} />
					<Route path="/products/flamme-stratford-fireplace" element={<Navigate replace to="/product/flamme-stratford-fireplace-with-35-surround/" />} />
					<Route path="/products/glass-disc-transducer-set-1pcs" element={<Navigate replace to="/collections/accessories/" />} />
					<Route path="/products/glass-disc-transducer-set-1pcs-transducer-2pcs-discs" element={<Navigate replace to="/collections/accessories/" />} />
					<Route path="/products/glass-disc-transducer-set-1pcs-transducer-2pcs-discs-1" element={<Navigate replace to="/collections/accessories/" />} />
					<Route path="/products/howick-38-natural-oak" element={<Navigate replace to="/product/flamme-howick-corner-fireplace-with-38-surround/EF374A-MT374C-NO/" />} />
					<Route path="/products/howick-38-natural-oak-1" element={<Navigate replace to="/product/flamme-howick-corner-fireplace-with-38-surround/EF374A-MT374C-EO/" />} />
					<Route path="/products/howick-38-white" element={<Navigate replace to="/product/flamme-howick-corner-fireplace-with-38-surround/EF374A-MT374C-W/" />} />
					<Route path="/products/ingleton-48-espresso-oak" element={<Navigate replace to="/product/flamme-ingleton-fireplace-with-48-surround/EF376A-MT376B-EO/" />} />
					<Route path="/products/ingleton-48-natural-oak-1" element={<Navigate replace to="/product/flamme-ingleton-fireplace-with-48-surround/EF376A-MT376B-NO/" />} />
					<Route path="/products/ingleton-48-white-1" element={<Navigate replace to="/product/flamme-ingleton-fireplace-with-48-surround/EF376A-MT376B-W/" />} />
					<Route path="/products/kingston-39-wall-mounted-fireplace-white" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-39-wall-mounted-fireplace-white-1" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-43-wall-mounted-fireplace" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-43-wall-mounted-fireplace-1" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-47-wall-mounted-fireplace-cement-grey" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-50-wall-mounted-fireplace-cement-grey" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-60-wall-mounted-fireplace" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-60-wall-mounted-fireplace-1" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-60-wall-mounted-fireplace-cement-grey" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-60-wall-mounted-fireplace-cement-grey-1" element={<Navigate replace to="/product/flamme-kingston-freestanding-electric-fireplace-/" />} />
					<Route path="/products/kingston-freestanding-electric-fireplace" element={<Navigate replace to="/product/knighton-43-110cm-51-130cm-3-sided-electric-free-standing-wall-mounted-fireplace/" />} />
					<Route path="/products/knighton-35-electric-fireplace" element={<Navigate replace to="/product/flamme-knighton-90cm-35-3-sided-electric-media-wall-fire/EF408A" />} />
					<Route path="/products/knighton-43-electric-fireplace" element={<Navigate replace to="/product/flamme-knighton-90cm-35-3-sided-electric-media-wall-fire/EF407A" />} />
					<Route path="/products/knighton-51-electric-fireplace" element={<Navigate replace to="/product/flamme-knighton-90cm-35-3-sided-electric-media-wall-fire/EF409A" />} />
					<Route path="/products/knighton-59-electric-fireplace" element={<Navigate replace to="/product/flamme-knighton-90cm-35-3-sided-electric-media-wall-fire/EF410A" />} />
					<Route path="/products/knighton-71-electric-fireplace" element={<Navigate replace to="/product/flamme-knighton-90cm-35-3-sided-electric-media-wall-fire/EF411A" />} />
					<Route path="/products/madura-39-white-1" element={<Navigate replace to="/product/madura-39-98cm-47-118cm-electric-fireplace-suite-2-sizes-4-colours/" />} />
					<Route path="/products/mardella-40-black" element={<Navigate replace to="/product/mardella-40-freestanding-fireplace/" />} />
					<Route path="/products/mardella-40-espresso-oak" element={<Navigate replace to="/product/mardella-40-freestanding-fireplace/" />} />
					<Route path="/products/mardella-40-expresso-oak-1" element={<Navigate replace to="/product/mardella-40-freestanding-fireplace/" />} />
					<Route path="/products/mardella-40-grey" element={<Navigate replace to="/product/mardella-40-freestanding-fireplace/" />} />
					<Route path="/products/mardella-40-natural-oak-1" element={<Navigate replace to="/product/mardella-40-freestanding-fireplace/" />} />
					<Route path="/products/mardella-40-natural-oak-2" element={<Navigate replace to="/product/mardella-40-freestanding-fireplace/" />} />
					<Route path="/products/mardella-40-white" element={<Navigate replace to="/product/mardella-40-freestanding-fireplace/" />} />
					<Route path="/products/stratford-35-natural-oak-1" element={<Navigate replace to="/product/flamme-stratford-fireplace-with-35-surround/EF374A-MT374B-NO/" />} />
					<Route path="/products/stratford-35-white-1" element={<Navigate replace to="/product/flamme-stratford-fireplace-with-35-surround/EF374A-MT374B-W/" />} />
					<Route path="/products/turbofan-for-mist-fire" element={<Navigate replace to="/product/replacement-fan-motor-dimplex-opti-myst-electric-fires/" />} />
					<Route path="/products/valenza-44-espresso-oak-electric-fireplace-suites" element={<Navigate replace to="/product/flamme-valenza-44-112cm-electric-fireplace-suite-3-colour-options/EF375A-MT375C-EO/" />} />
					<Route path="/products/valenza-44-natural-oak-electric-fireplace-suites" element={<Navigate replace to=" /product/flamme-valenza-44-112cm-electric-fireplace-suite-3-colour-options/EF375A-MT375C-NO/" />} />
					<Route path="/products/valenza-44-white-electric-fireplace-suites" element={<Navigate replace to="/product/flamme-valenza-44-112cm-electric-fireplace-suite-3-colour-options/EF375A-MT375C-W/" />} />
					<Route path="/products/valenza-44-white-electric-fireplace-suites-1" element={<Navigate replace to="/product/flamme-valenza-44-112cm-electric-fireplace-suite-3-colour-options/" />} />
					<Route path="/products/varley-46-espresso-oak-1" element={<Navigate replace to="/product/madura-39-98cm-47-118cm-electric-fireplace-suite-2-sizes-4-colours/" />} />
					<Route path="/products/varley-46-natural-oak" element={<Navigate replace to="/product/madura-39-98cm-47-118cm-electric-fireplace-suite-2-sizes-4-colours/" />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
