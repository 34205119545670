export default function FaqPage() {
    return <>
        <div className="container my-5">
            <h1 className="text-center mb-5 pb-5">FAQs</h1>
            <div className="">
                <div className="fs-md-19">
                    <p className="my-0">Q: Where can I go to see a fireplace in person?</p>
                    <p className="mb-1">A: We are based in Hixon, Staffordshire. Our address is:</p>
                    <p className="my-0">Unit 6</p>
                    <p className="my-0">Airfield industrial estate</p>
                    <p className="my-0">Hixon</p>
                    <p className="my-0">Staffordshire</p>
                    <p className="my-0">ST18 0PF</p>

                    <div className="py-4"></div>

                    <p className="my-0">Q: How do I contact someone directly if I cant find my question here?</p>
                    <p>A: If you have more questions, please don't hesitate to email us at customerservice@moddelo.co.uk or call us directly 01785 719005 and someone will assist you.</p>

                    <div className="py-4"></div>

                    <p className="my-0">Q: Will my fireplace or stove come with a guarantee if I buy from your website?</p>
                    <p>A: Yes, of course. We are the only official suppliers for all the products on our site and therefore you will receive a full manufacturers warranty for any product you buy.</p>

                    <div className="py-4"></div>

                    <p className="my-0">Q: How much will my fire cost to run?</p>
                    <p>A: This depends on the price you pay for your electricity and the type of fire. It will also vary according to the heat setting on which you have it. You can calculate the maximum running cost per hour by multiplying your current cost of electricity per kilowatt hour by the maximum heat output of your chosen fire, this can be found in the Key Features section on the product page.</p>

                    <div className="py-4"></div>

                    <p className="my-0">Q: Is there a charge for delivery?</p>
                    <p>A: No, delivery is free to all Mainland UK addresses.</p>

                    <div className="py-4"></div>

                    <p className="my-0">Q: What’s your return policy?</p>
                    <p>A: 30-day returns are allowed if you are not satisfied with our products and the return process is simple and free. If you have any questions, we are always happy to help.</p>

                    <div className="py-4"></div>

                    <p className="my-0">Q: Can I Cancel my order?</p>
                    <p>A: If you want to cancel your order, please do so as soon as possible. If we have already processed your order, you need to contact us and return the product, please contact <a className="text-primary text-decoration-underline" href="tel:01785719005">01785 719005</a> or email us at <a className="text-primary text-decoration-underline" href="mailto:customerservice@moddelo.co.uk">customerservice@moddelo.co.uk</a></p>

                    <div className="py-4"></div>

                    <p className="my-0">Q: Can I track my order?</p>
                    <p>A: We will upload the tracking number to your order when the parcel has been sent, so you can track the shipment from our warehouse right to your front door.</p>

                    <div className="py-4"></div>

                    <p className="my-0">Q: I'm cautious about giving my credit card details over the internet.</p>
                    <p>A: Security is probably one of the most significant concerns for shoppers during an online transaction. We are a fully registered and regulated company and we offer a wide range of payment options including Klarna.
                        Our web store is secured with SSL certificate and passes the highest level of PCI DSS standard (Payment Card Industry Data Security), which means that the information you input is encrypted and it will not be available for third parties.</p>
                </div>
            </div>
        </div>
    </>;
}