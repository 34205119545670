export class VariantOption {
    optionHandle: string; // mounting-type
    optionName: string; // Mounting Type
    viewName: string;   // Free Standing
    handle: string; // free-standing
}

export class ProductOption {
    viewName: string;   // Free Standing
    handle: string; // free-standing
}

export class ProductOptions {
    optionHandle: string; // mounting-type
    optionName: string; // Mounting Type
    options: ProductOption[];
}

export class ProductMedia {
    url: string;
    order: number;
}


export class VariantEntity {
    id: string;
    title: string;
    handle: string;
    sku: string;
    available: boolean = false;
    featuredMedia: string;
    price: number;
    options: VariantOption[];
    postageProvider: string;    // UPS / AXL
    stockCount: number;
}

export default class ProductEntity {
    id: string;
    title: string;
    description: string;
    HTMLdescription: string;
    handle: string;
    sku: string;
    externalRef: string = '';
    created: Date;
    available: boolean;
    featuredMedia: string;
    media: ProductMedia[];
    price: number;
    tags: string[];
    postageProvider: string;
    options: ProductOptions[];
    variants: VariantEntity[];
    brand: string; // moddelo / flameko

    metafields: any;
}


export class ProductBuilder {
    public New(): ProductEntity {
        const product = new ProductEntity();
        product.options = [];
        product.variants = [];
        product.media = [];
        return product;
    }
}