import { useNavigate } from "react-router-dom";
import UserService from "../../services/session/user-service";
import { useState } from "react";
import UserSession from "../../models/data/user-session";

export default function AccountPage() {

    const navigate = useNavigate();
    const [session] = useState<UserSession | null>(UserService.getCurrentSession());

    return <div>
        <div className="bg-light py-4">

            <div className="container px-0">
                <div className="row">
                    { session?.email && <h3 className="text-center">Welcome, {session.email} ({session.name})</h3> }
                </div>
                <div className="row px-0 mt-4">
                    <button className="btn btn-danger mx-auto" style={{ maxWidth: 300 }} onClick={() => UserService.logout(() => {
                        navigate('/')
                    })}>Log Out</button>
                </div>
            </div>
        </div>
    </div>;
}