import { HTTP_CONSTANTS } from "../../constants/http-constants";
import CollectionEntity from "../../models/data/collection";
import { CollectionResponse } from "../../models/responses/CollectionResp";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";

export class CollectionCreateRequest {
    public title: string;
}

function GetCollection(
    handle: string,
    callback: ResponseCallback<CollectionEntity>
): void {
    HttpService.get<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/collection/${handle}`,
        (resp: CollectionEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function AdminGetCollection(
    handle: string,
    callback: ResponseCallback<CollectionEntity>
): void {
    HttpService.get<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/collection-admin/${handle}`,
        (resp: CollectionEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function CreateCollection(
    request: CollectionCreateRequest,
    callback: ResponseCallback<CollectionEntity>
): void {
    HttpService.post<CollectionCreateRequest, any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/collection`,
        request,
        (resp: CollectionEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function SaveCollection(
    id: string,
    request: CollectionCreateRequest,
    callback: ResponseCallback<CollectionEntity>
): void {
    HttpService.put<CollectionCreateRequest, any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/collection/${id}`,
        request,
        (resp: CollectionEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function DeleteCollection(
    id: string,
    callback: ResponseCallback<CollectionEntity>
): void {
    HttpService.delete<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/collection/${id}`,
        (resp: any) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function GetProducts(
    handle: string,
    callback: ResponseCallback<CollectionResponse>
): void {
    HttpService.get<any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/collection/${handle}`,
        (resp: CollectionResponse) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function AddProduct(
    id: string,
    productId: string,
    callback: ResponseCallback<CollectionEntity>
): void {
    HttpService.put<any, any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/collection/${id}/add/${productId}`,
        {},
        (resp: CollectionEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function RemoveProduct(
    id: string,
    productId: string,
    callback: ResponseCallback<CollectionEntity>
): void {
    HttpService.put<any, any>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/collection/${id}/remove/${productId}`,
        {},
        (resp: CollectionEntity) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}


const CollectionService = {
    AdminGetCollection,
    GetCollection,
    GetProducts,
    CreateCollection,
    SaveCollection,
    DeleteCollection,
    AddProduct,
    RemoveProduct
};

export default CollectionService;
