import React, {useEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import UserService from "../../services/session/user-service";
import NavHeader from "../../components/nav-header/nav-header";
import Footer from "../../components/footer/footer";
import CartService from "../../services/ui/cart-service";

export default function Theme() {
    const navigate = useNavigate();

    useEffect(() => {
        CartService.LoadCart();
        var sesionExpiredSub = UserService.sessionExpiredEvent.subscribe(() => {
            navigate("/login");
        });

        return () => {
            UserService.sessionExpiredEvent.unsubscribe(sesionExpiredSub);
        };
    }, [navigate]);

    try {
        const location = useLocation();

        const routeThemes: { path: string; colour: string }[] = [
            {path: "/", colour: "#F8FAFB"},
            {path: "/login", colour: "#F8FAFB"},
            {path: "/register", colour: "#F8FAFB"},
            {path: "/recovery", colour: "#F8FAFB"},
        ];

        var metaTag = document.querySelector('meta[name="theme-color"]');
        let themeColour = "#FFF";

        routeThemes.forEach((_: { path: string; colour: string }) => {
            if (_.path === location.pathname) themeColour = _.colour;
        });

        if (metaTag != null) metaTag.setAttribute("content", themeColour);
    } catch (exception: any) {
        console.warn(exception);
    }

    const idevice =
        ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

    return (
        <div className="">
            <NavHeader/>
            <div className={`d-flex align-items-stretch flex-column h-100 w-100 ${idevice ? "i_device" : ""} bg-light`}
                 style={{'minHeight': '100lvh'}}>
                <Outlet/>
                <div className={`position-fixed bottom-0 end-0`}>
                    <div className={`p-3 p-md-3`}>
                        <a href={"https://wa.me/441785719005"} target={"_blank"} rel={"noreferrer"}>
                            <img
                                alt={"WhatsApp"}
                                src={"https://cdn-moddelo.s3.eu-west-2.amazonaws.com/site-media/b50fde95-21bb-4204-9f86-5c8660419343_chatonwa.png"}
                                className={`d-block w-100`} style={{maxWidth: '175px'}}/>
                        </a>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
